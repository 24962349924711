import React from 'react';
import {Col, Container, Row} from 'react-awesome-styled-grid';
import {Button} from '../core/Button/Button.styles';
import {StyledLink} from '../core/TextLink/TextLink.styles';
import {Footer, Heading04} from '../core/Typography/Typography.styles';
import {StyleHeader, TitleContainer} from './LocalStyles';

const ButtonGuide = () => {
  const buttonData = [
    {
      title: 'Primary (Small)',
      variants: {
        default: [
          <Button key={1} primary small>
            Primary Button
          </Button>,
          <Button key={2} primary small iconLeft>
            <span className="material-icons sm">autorenew</span> Primary Button
          </Button>,
          <Button key={3} primary small iconRight>
            Primary Button <span className="material-icons sm">autorenew</span>
          </Button>,
        ],
        disabled: [
          <Button key={1} primary small disabled>
            Primary Button
          </Button>,
          <Button key={2} primary small iconLeft disabled>
            <span className="material-icons sm">autorenew</span> Primary Button
          </Button>,
          <Button key={3} primary small iconRight disabled>
            Primary Button <span className="material-icons sm">autorenew</span>
          </Button>,
        ],
      },
    },
    {
      title: 'Primary (Medium)',
      variants: {
        default: [
          <Button key={1} primary medium>
            Primary Button
          </Button>,
          <Button key={2} primary iconLeft medium>
            <span className="material-icons sm">autorenew</span> Primary Button
          </Button>,
          <Button key={3} primary iconRight medium>
            Primary Button <span className="material-icons sm">autorenew</span>
          </Button>,
        ],
        disabled: [
          <Button key={1} primary medium disabled>
            Primary Button
          </Button>,
          <Button key={2} primary medium iconLeft disabled>
            <span className="material-icons sm">autorenew</span> Primary Button
          </Button>,
          <Button key={3} primary medium iconRight disabled>
            Primary Button <span className="material-icons sm">autorenew</span>
          </Button>,
        ],
      },
    },
    {
      title: 'Primary (Large)',
      variants: {
        default: [
          <Button key={1} primary large>
            Primary Button
          </Button>,
          <Button key={2} primary iconLeft large>
            <span className="material-icons lg">autorenew</span> Primary Button
          </Button>,
          <Button key={3} primary iconRight large>
            Primary Button <span className="material-icons lg">autorenew</span>
          </Button>,
        ],
        disabled: [
          <Button key={1} primary large disabled>
            Primary Button
          </Button>,
          <Button key={2} primary iconLeft large disabled>
            <span className="material-icons lg">autorenew</span> Primary Button
          </Button>,
          <Button key={3} primary iconRight large disabled>
            Primary Button <span className="material-icons lg">autorenew</span>
          </Button>,
        ],
      },
    },
    {
      title: 'Secondary (Small)',
      variants: {
        default: [
          <Button key={1} secondary small>
            Secondary Button
          </Button>,
          <Button key={2} secondary iconLeft small>
            <span className="material-icons sm">autorenew</span> Secondary
            Button
          </Button>,
          <Button key={3} secondary iconRight small>
            Secondary Button{' '}
            <span className="material-icons sm">autorenew</span>
          </Button>,
        ],
        disabled: [
          <Button key={1} secondary small disabled>
            Secondary Button
          </Button>,
          <Button key={2} secondary small iconLeft disabled>
            <span className="material-icons sm">autorenew</span> Secondary
            Button
          </Button>,
          <Button key={3} secondary small iconRight disabled>
            Secondary Button{' '}
            <span className="material-icons sm">autorenew</span>
          </Button>,
        ],
      },
    },
    {
      title: 'Secondary (Medium)',
      variants: {
        default: [
          <Button key={1} secondary medium>
            Secondary Button
          </Button>,
          <Button key={2} secondary medium iconLeft>
            <span className="material-icons sm">autorenew</span> Secondary
            Button
          </Button>,
          <Button key={3} secondary medium iconRight>
            Secondary Button{' '}
            <span className="material-icons sm">autorenew</span>
          </Button>,
        ],
        disabled: [
          <Button key={1} secondary medium disabled>
            Secondary Button
          </Button>,
          <Button key={2} secondary medium iconLeft disabled>
            <span className="material-icons sm">autorenew</span> Secondary
            Button
          </Button>,
          <Button key={3} secondary medium iconRight disabled>
            Secondary Button{' '}
            <span className="material-icons sm">autorenew</span>
          </Button>,
        ],
      },
    },
    {
      title: 'Secondary (Large)',
      variants: {
        default: [
          <Button key={1} secondary large>
            Secondary Button
          </Button>,
          <Button key={2} secondary iconLeft large>
            <span className="material-icons lg">autorenew</span> Secondary
            Button
          </Button>,
          <Button key={3} secondary iconRight large>
            Secondary Button{' '}
            <span className="material-icons lg">autorenew</span>
          </Button>,
        ],
        disabled: [
          <Button key={1} secondary large disabled>
            Secondary Button
          </Button>,
          <Button key={2} secondary iconLeft large disabled>
            <span className="material-icons lg">autorenew</span> Secondary
            Button
          </Button>,
          <Button key={3} secondary iconRight large disabled>
            Secondary Button{' '}
            <span className="material-icons lg">autorenew</span>
          </Button>,
        ],
      },
    },
    {
      title: 'Tertiary (Small)',
      variants: {
        default: [
          <Button key={1} tertiary small>
            Tertiary Button
          </Button>,
          <Button key={2} tertiary iconLeft small>
            <span className="material-icons sm">autorenew</span> Tertiary Button
          </Button>,
          <Button key={3} tertiary iconRight small>
            Tertiary Button <span className="material-icons sm">autorenew</span>
          </Button>,
        ],
        disabled: [
          <Button key={1} tertiary small disabled>
            Tertiary Button
          </Button>,
          <Button key={2} tertiary iconLeft small disabled>
            <span className="material-icons sm">autorenew</span> Tertiary Button
          </Button>,
          <Button key={3} tertiary iconRight small disabled>
            Tertiary Button <span className="material-icons sm">autorenew</span>
          </Button>,
        ],
      },
    },
    {
      title: 'Tertiary (Medium)',
      variants: {
        default: [
          <Button key={1} tertiary medium>
            Tertiary Button
          </Button>,
          <Button key={2} tertiary iconLeft medium>
            <span className="material-icons sm">autorenew</span> Tertiary Button
          </Button>,
          <Button key={3} tertiary iconRight medium>
            Tertiary Button <span className="material-icons sm">autorenew</span>
          </Button>,
        ],
        disabled: [
          <Button key={1} tertiary medium disabled>
            Tertiary Button
          </Button>,
          <Button key={2} tertiary iconLeft medium disabled>
            <span className="material-icons sm">autorenew</span> Tertiary Button
          </Button>,
          <Button key={3} tertiary iconRight medium disabled>
            Tertiary Button <span className="material-icons sm">autorenew</span>
          </Button>,
        ],
      },
    },
    {
      title: 'Tertiary (Large)',
      variants: {
        default: [
          <Button key={1} tertiary large>
            Tertiary Button
          </Button>,
          <Button key={2} tertiary iconLeft large>
            <span className="material-icons lg">autorenew</span> Tertiary Button
          </Button>,
          <Button key={3} tertiary iconRight large>
            Tertiary Button <span className="material-icons lg">autorenew</span>
          </Button>,
        ],
        disabled: [
          <Button key={1} tertiary large disabled>
            Tertiary Button
          </Button>,
          <Button key={2} tertiary iconLeft large disabled>
            <span className="material-icons lg">autorenew</span> Tertiary Button
          </Button>,
          <Button key={3} tertiary iconRight large disabled>
            Tertiary Button <span className="material-icons lg">autorenew</span>
          </Button>,
        ],
      },
    },
    {
      title: 'Text links (Small)',
      variants: {
        default: [
          <StyledLink key={1} small>
            Text Link
          </StyledLink>,
          <StyledLink key={2} arrow small>
            Text Link
          </StyledLink>,
        ],
        disabled: [
          <StyledLink key={1} small disabled>
            Text Link
          </StyledLink>,
          <StyledLink key={2} arrow small disabled>
            Text Link
          </StyledLink>,
        ],
      },
    },
    {
      title: 'Text links (Medium)',
      variants: {
        default: [
          <StyledLink key={1} medium>
            Text Link
          </StyledLink>,
          <StyledLink key={2} arrow medium>
            Text Link
          </StyledLink>,
        ],
        disabled: [
          <StyledLink key={1} medium disabled>
            Text Link
          </StyledLink>,
          <StyledLink key={2} arrow medium disabled>
            Text Link
          </StyledLink>,
        ],
      },
    },
    {
      title: 'Text links (Large)',
      variants: {
        default: [
          <StyledLink key={1} large>
            Text Link
          </StyledLink>,
          <StyledLink key={2} arrow large>
            Text Link
          </StyledLink>,
        ],
        disabled: [
          <StyledLink key={1} large disabled>
            Text Link
          </StyledLink>,
          <StyledLink key={2} arrow large disabled>
            Text Link
          </StyledLink>,
        ],
      },
    },
  ];
  return (
    <>
      <StyleHeader>
        <TitleContainer>
          <h1>Buttons</h1>
        </TitleContainer>
      </StyleHeader>
      <Container style={{margin: '80px auto'}}>
        {buttonData.map(({title, variants}, index) => (
          <div key={index} style={{marginTop: '32px'}}>
            <Row
              justify="center"
              style={{marginTop: '32px', marginBottom: '32px'}}
            >
              <Col xs={4} justify="flex-end">
                <Heading04 fontWeight="medium">{title}</Heading04>
              </Col>
            </Row>
            {Object.entries(variants).map(([variant, Elements], idx) => (
              <Row key={idx} style={{margin: '10px'}}>
                <Col md={1}></Col>
                <Col md={1}>
                  <Footer uppercase fontWeight="regular">
                    {variant}
                  </Footer>
                </Col>
                {Elements.map((Element, i) => (
                  <Col align="flex-start" md={2} key={i}>
                    {Element}
                  </Col>
                ))}
              </Row>
            ))}
            <Row></Row>
          </div>
        ))}
      </Container>
    </>
  );
};

export default ButtonGuide;
