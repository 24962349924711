import styled from 'styled-components';
import {
  dacastBtn,
  dacastBtnIconLeft,
  dacastBtnIconRight,
  dacastBtnLarge,
  dacastBtnMedium,
  dacastBtnPricingPrimary,
  dacastBtnPricingSecondary,
  dacastBtnPricingTertiary,
  dacastBtnPrimary,
  dacastBtnSecondary,
  dacastBtnSmall,
  dacastBtnTertiary,
} from './Button.mixins';

export const Button = styled.button`
  ${dacastBtn}
  ${({pricingPrimary}) => pricingPrimary && dacastBtnPricingPrimary}
  ${({pricingSecondary}) => pricingSecondary && dacastBtnPricingSecondary}
  ${({pricingTertiary}) => pricingTertiary && dacastBtnPricingTertiary}
  ${({primary}) => primary && dacastBtnPrimary}
  ${({secondary}) => secondary && dacastBtnSecondary}
  ${({tertiary}) => tertiary && dacastBtnTertiary}
  ${({small}) => small && dacastBtnSmall}
  ${({medium}) => medium && dacastBtnMedium}
  ${({large}) => large && dacastBtnLarge}
  ${({iconLeft}) => iconLeft && dacastBtnIconLeft}
  ${({iconRight}) => iconRight && dacastBtnIconRight}
`;
