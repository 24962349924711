import React, {useEffect} from 'react';
import Layout from '../components/Layout/index.js';
import ButtonGuide from '../components/StyleGuide/ButtonGuide';
import ColorGuide from '../components/StyleGuide/ColorGuide';
import SpacingGuide from '../components/StyleGuide/SpacingGuide';
import TypographyGuide from '../components/StyleGuide/TypographyGuide';

const StyleGuide = () => {
  useEffect(() => {
    const tweetButtons = document.querySelectorAll('#tweetButton');
    tweetButtons.forEach(button => {
      button.addEventListener('click', () => {
        var tweetPhrase = button.previousElementSibling.innerHTML;
        button.href = `https://twitter.com/share?text=${tweetPhrase}`;
      });
    });
  }, []);

  return (
    <Layout>
      <ColorGuide />
      <TypographyGuide />
      <ButtonGuide />
      <SpacingGuide />
      <div className="testContainer">
        <div className="tweetBox">
          <div className="tweetCaption">
            <p>
              The best pithy quotes don&apos;t have to be so deep. And
              that&apos;s pretty deep.
              <span>
                <a
                  className="shareButton"
                  target="_blank"
                  rel="noreferrer"
                  id="tweetButton"
                >
                  click to Tweet
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <path
                        d="M38.7009 17.2482C37.6724 17.7043 36.5661 18.0127 35.4058 18.1505C36.5905 17.4407 37.4996 16.3177 37.9287 14.9774C36.8202 15.6349 35.5919 16.1119 34.2858 16.3691C33.2394 15.2546 31.7484 14.5581 30.0975 14.5581C26.9299 14.5581 24.3608 17.1272 24.3608 20.2947C24.3608 20.7444 24.4117 21.182 24.5101 21.6021C19.7425 21.363 15.5152 19.0793 12.6856 15.6075C12.1919 16.4546 11.9087 17.4407 11.9087 18.4919C11.9087 20.4817 12.9222 22.2383 14.4607 23.2668C13.5208 23.2373 12.6356 22.9793 11.8625 22.5485C11.8621 22.5729 11.8621 22.5973 11.8621 22.6212C11.8621 25.4007 13.8403 27.7191 16.4641 28.2457C15.9833 28.3775 15.4755 28.4472 14.9531 28.4472C14.5826 28.4472 14.2237 28.4117 13.8737 28.345C14.6036 30.6236 16.7217 32.2822 19.2326 32.3288C17.2689 33.8677 14.7957 34.7845 12.1072 34.7845C11.6452 34.7845 11.1874 34.7576 10.7395 34.7041C13.2769 36.3324 16.293 37.2817 19.5325 37.2817C30.0842 37.2817 35.8546 28.5405 35.8546 20.9591C35.8546 20.7106 35.849 20.4629 35.8379 20.2173C36.9597 19.4087 37.9321 18.3986 38.7009 17.2482Z"
                        fill="#1DA1F2"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="48" height="48" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </span>
            </p>
          </div>
        </div>
        <div className="tweetBox">
          <p className="tweetmsg">Hola Mundo 2</p>
          <a
            className="twitter-share-button"
            target="_blank"
            rel="noreferrer"
            id="tweetButton"
          >
            Tweet
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default StyleGuide;
