import React from 'react';
import PropTypes from 'prop-types';
import {
  CellDesc,
  CellTitle,
  ColorData,
  ColorGrid,
  ColorHex,
  ColorRow,
  ColorScale,
  ColorTone,
  ColorWrapper,
  Copied,
  RowTitle,
  StyleHeader,
  TitleContainer,
  Tooltip,
} from './LocalStyles';
import {useState} from 'react';
import theme from '../Layout/theme';

const toneDescriptions = [
  {
    title: 'Neutral',
    desc:
      'These colors are used as supporting secondary colors in backgrounds, text colors, seperators, models, etc',
    palette: theme.colorGuide.neutral,
  },
  {
    title: 'Primary',
    desc:
      'The primary color palette is used across all the interactive elements such as CTA’s, links, inputs, active states, etc',
    palette: theme.colorGuide.primary,
  },
  {
    title: 'Secondary',
    desc:
      'The secondary color palette is used alongside the primary to indicate to the user it’s the secondary focus.',
    palette: theme.colorGuide.secondary,
  },
  {
    title: 'Success',
    desc:
      'These colors depict an emotion of positivity. Generally used across success, complete states.',
    palette: theme.colorGuide.success,
  },
  {
    title: 'Warning',
    desc:
      'These colors depict an emotion of holding. Generally used across warning or on-hold states.',
    palette: theme.colorGuide.warning,
  },
  {
    title: 'Error',
    desc:
      'These colors depict an emotion of negativity. Generally used across error states.',
    palette: theme.colorGuide.error,
  },
  {
    title: 'Shades',
    desc:
      'These colors are used as supporting secondary colors in backgrounds, text colors, seperators, models, etc',
    palette: theme.colorGuide.shades,
  },
  {
    title: 'Dark Mode',
    desc:
      'These colors depict an emotion of negativity. Generally used across error states.',
    palette: theme.colorGuide.darkMode,
  },
];

const ColorContainer = ({scale, hex, palette}) => {
  const [showToolTip, setShowToolTip] = useState(false);
  const [showCopied, setShowCopied] = useState(false);
  const handleHoverIn = () => {
    setShowToolTip(!showToolTip);
  };
  const handleHoverOut = () => {
    setShowToolTip(!showToolTip);
  };
  const handleClick = () => {
    navigator.clipboard.writeText(`theme.colorGuide.${palette}[${scale}]`);
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 700);
  };
  return (
    <ColorWrapper
      onMouseEnter={handleHoverIn}
      onMouseLeave={handleHoverOut}
      onClick={handleClick}
    >
      <ColorTone showToolTip={showToolTip} color={hex} />
      <ColorData>
        <ColorScale>{scale}</ColorScale>
        <ColorHex>{hex}</ColorHex>
      </ColorData>
      <Tooltip showToolTip={showToolTip}>
        <p>
          theme.colorGuide.{palette}[{scale}]
        </p>
      </Tooltip>
      <Copied showCopied={showCopied} />
    </ColorWrapper>
  );
};

const ColorGuide = () => {
  return (
    <>
      <StyleHeader>
        <TitleContainer>
          <h1>Colors</h1>
        </TitleContainer>
      </StyleHeader>
      <ColorGrid>
        {toneDescriptions.map(({title, desc, palette}, index) => {
          const themePalette = Object.keys(theme.colorGuide)[index];
          return (
            <ColorRow key={index}>
              <RowTitle>
                <CellTitle>{title}</CellTitle>
                <CellDesc>{desc}</CellDesc>
              </RowTitle>
              {Object.entries(palette).map(([scale, hex]) => (
                <ColorContainer
                  palette={themePalette}
                  scale={scale}
                  hex={hex}
                  key={hex}
                />
              ))}
            </ColorRow>
          );
        })}
      </ColorGrid>
    </>
  );
};

export default ColorGuide;

ColorContainer.propTypes = {
  scale: PropTypes.number,
  hex: PropTypes.string,
  palette: PropTypes.string,
};
