import styled from 'styled-components';
import {
  dacastLink,
  dacastLinkArrow,
  dacastLinkArrowDisabled,
  dacastLinkDisabled,
  dacastLinkLarge,
  dacastLinkMedium,
  dacastLinkSmall,
} from './TextLink.mixins';

export const StyledLink = styled.a`
  ${dacastLink}
  ${({arrow}) => arrow && dacastLinkArrow}
  ${({disabled}) => disabled && dacastLinkDisabled}
  ${({arrow, disabled}) => arrow && disabled && dacastLinkArrowDisabled}
  ${({small}) => small && dacastLinkSmall}
  ${({medium}) => medium && dacastLinkMedium}
  ${({large}) => large && dacastLinkLarge}
`;
