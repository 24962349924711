import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  StyleHeader,
  TitleContainer,
  SpacingWrapper,
  GridRow,
  SpacingContainer,
  GridCol,
  GridTitleRow,
  SpacingItem,
  Wrapper,
  Tooltip,
} from './LocalStyles';
import {Paragraph02} from '../core/Typography/Typography.styles';

const SpacingElement = ({
  item: {title, width, margin, spacing, elements, scale},
}) => {
  const [showToolTip, setShowToolTip] = useState(false);
  const handleHoverIn = () => {
    setShowToolTip(!showToolTip);
  };
  const handleHoverOut = () => {
    setShowToolTip(!showToolTip);
  };

  return (
    <>
      <SpacingItem>
        <GridTitleRow>{title}</GridTitleRow>
        <div style={{display: 'flex', width: '100%'}}>
          <SpacingWrapper
            onMouseEnter={handleHoverIn}
            onMouseLeave={handleHoverOut}
            scale={scale}
            width={width + 64}
          >
            <SpacingContainer width={width}>
              <GridRow margin={margin} spacing={spacing}>
                {Array.from({length: elements}).map((_, index) => (
                  <GridCol key={index}>&nbsp;</GridCol>
                ))}
              </GridRow>
            </SpacingContainer>
            <Tooltip showToolTip={showToolTip} scale={1.5}>
              <Paragraph02 fontWeight="normal">
                {elements} columns, {spacing}px between elements, {margin}{' '}
                margin (set to {scale} scale for displaying purposes)
              </Paragraph02>
            </Tooltip>
          </SpacingWrapper>
        </div>
      </SpacingItem>
    </>
  );
};

const SpacingGuide = () => {
  const spacingData = [
    {
      title: '1920px (Centered)',
      width: 1920,
      margin: 'auto',
      spacing: 40,
      elements: 12,
      scale: 0.5,
    },
    {
      title: '1920px (Fluid)',
      width: 1920,
      margin: '120px',
      spacing: 40,
      elements: 12,
      scale: 0.5,
    },
    {
      title: '1440px (Fluid)',
      width: 1440,
      margin: '120px',
      spacing: 40,
      elements: 12,
      scale: 0.5,
    },
    {
      title: '1366px (Fluid)',
      width: 1366,
      margin: '120px',
      spacing: 40,
      elements: 12,
      scale: 0.5,
    },
    {
      title: '1152px (Fluid)',
      width: 1152,
      margin: '120px',
      spacing: 40,
      elements: 12,
      scale: 0.5,
    },
    {
      title: '1024px (Fluid)',
      width: 1024,
      margin: '40px',
      spacing: 20,
      elements: 8,
      scale: 0.5,
    },
    {
      title: '834px (Fluid)',
      width: 834,
      margin: '40px',
      spacing: 20,
      elements: 8,
      scale: 0.5,
    },
    {
      title: '768px (Fluid)',
      width: 768,
      margin: '40px',
      spacing: 20,
      elements: 8,
      scale: 0.5,
    },
    {
      title: '411px (Fluid)',
      width: 411,
      margin: '16px',
      spacing: 8,
      elements: 4,
      scale: 0.5,
    },
    {
      title: '320px (Fluid)',
      width: 320,
      margin: '16px',
      spacing: 8,
      elements: 4,
      scale: 0.5,
    },
  ];
  return (
    <>
      <StyleHeader>
        <TitleContainer>
          <h1>Spacing</h1>
        </TitleContainer>
      </StyleHeader>
      <Wrapper>
        {spacingData.map((item, index) => (
          <SpacingElement item={item} key={index} />
        ))}
      </Wrapper>
    </>
  );
};

export default SpacingGuide;

SpacingElement.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    width: PropTypes.number,
    margin: PropTypes.string,
    spacing: PropTypes.number,
    elements: PropTypes.number,
    scale: PropTypes.number,
  }),
};

SpacingElement.defaultProps = {
  scale: 1,
};
