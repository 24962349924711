import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Heading03, Heading04} from '../core/Typography/Typography.styles';
import {
  Wrapper,
  SectionContainer,
  SectionTitle,
  SectionTitleContainer,
  FontStyle,
  FontStyleContainer,
  StyleHeader,
  TitleContainer,
  Tooltip,
  Copied,
  FontWrapper,
  TableSectionTitle,
  TableContainer,
} from './LocalStyles';
import {Row, Col} from 'react-awesome-styled-grid';

const TypographyContainer = ({element, tag, title}) => {
  const [showToolTip, setShowToolTip] = useState(false);
  const [showCopied, setShowCopied] = useState(false);
  const handleHoverIn = () => {
    setShowToolTip(!showToolTip);
  };
  const handleHoverOut = () => {
    setShowToolTip(!showToolTip);
  };
  const handleClick = () => {
    navigator.clipboard.writeText(
      `<${tag} className="${element[0]}"> </${tag}>`
    );
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 700);
  };
  return (
    <FontWrapper
      onMouseEnter={handleHoverIn}
      onMouseLeave={handleHoverOut}
      onClick={handleClick}
    >
      <FontStyle showToolTip={showToolTip}>
        {React.createElement(
          tag,
          {className: element[0]},
          `${tag !== 'p' ? title : ''} ${element[1]}`
        )}
      </FontStyle>
      <Tooltip showToolTip={showToolTip}>
        <p>
          &lt;{tag} className=&quot;{element[0]}&quot;&gt; &lt;/{tag}&gt;
        </p>
      </Tooltip>
      <Copied showCopied={showCopied} />
    </FontWrapper>
  );
};

const TypographyGuide = () => {
  const data = [
    {
      title: 'Display 01',
      tag: 'h1',
      elements: {
        'dacast-display01 dacast-display01--medium': ' Medium',
        'dacast-display01 dacast-display01--bold': ' Bold',
        'dacast-display01 dacast-display01--black': ' Black',
      },
    },
    {
      title: 'Display 02',
      tag: 'h1',
      elements: {
        'dacast-display02 dacast-display02--medium': ' Medium',
        'dacast-display02 dacast-display02--bold': ' Bold',
        'dacast-display02 dacast-display02--black': ' Black',
      },
    },
    {
      title: 'Heading 01',
      tag: 'h1',
      elements: {
        'dacast dacast-medium': ' Medium',
        'dacast dacast-bold': ' Bold',
        'dacast dacast-black': ' Black',
        'dacast dacast-medium dacast-medium--small': ' (S) Medium',
        'dacast dacast-bold dacast-bold--small': ' (S) Bold',
        'dacast dacast-black dacast-black--small': ' (S) Black',
      },
    },
    {
      title: 'Heading 02',
      tag: 'h2',
      elements: {
        'dacast dacast-medium': ' Medium',
        'dacast dacast-bold': ' Bold',
        'dacast dacast-black': ' Black',
        'dacast dacast-medium dacast-medium--small': ' (S) Medium',
        'dacast dacast-bold dacast-bold--small': ' (S) Bold',
        'dacast dacast-black dacast-black--small': ' (S) Black',
      },
    },
    {
      title: 'Heading 03',
      tag: 'h3',
      elements: {
        'dacast dacast-medium': ' Medium',
        'dacast dacast-bold': ' Bold',
        'dacast dacast-black': ' Black',
        'dacast dacast-medium dacast-medium--small': ' (S) Medium',
        'dacast dacast-bold dacast-bold--small': ' (S) Bold',
        'dacast dacast-black dacast-black--small': ' (S) Black',
      },
    },
    {
      title: 'Heading 04',
      tag: 'h4',
      elements: {
        'dacast dacast-medium': ' Medium',
        'dacast dacast-bold': ' Bold',
        'dacast dacast-black': ' Black',
        'dacast dacast-medium dacast-medium--small': ' (S) Medium',
        'dacast dacast-bold dacast-bold--small': ' (S) Bold',
        'dacast dacast-black dacast-black--small': ' (S) Black',
      },
    },
    {
      title: 'Heading 05',
      tag: 'h5',
      elements: {
        'dacast dacast-medium': ' Medium',
        'dacast dacast-bold': ' Bold',
        'dacast dacast-black': ' Black',
        'dacast dacast-medium dacast-medium--small': ' (S) Medium',
        'dacast dacast-bold dacast-bold--small': ' (S) Bold',
        'dacast dacast-black dacast-black--small': ' (S) Black',
      },
    },
    {
      title: 'Heading 06',
      tag: 'h6',
      elements: {
        'dacast dacast-medium': ' Medium',
        'dacast dacast-bold': ' Bold',
        'dacast dacast-black': ' Black',
        'dacast dacast-medium dacast-medium--small': ' (S) Medium',
        'dacast dacast-bold dacast-bold--small': ' (S) Bold',
        'dacast dacast-black dacast-black--small': ' (S) Black',
      },
    },
    {
      title: 'Subheading',
      tag: 'p',
      elements: {
        'dacast-subheading': 'A subheading goes here.',
        'dacast-subheading dacast-subheading--medium':
          'A subheading goes here.',
        'dacast-subheading dacast-subheading--underline':
          'A subheading goes here.',
      },
    },
    {
      title: 'Paragraph 01',
      tag: 'p',
      elements: {
        'dacast-p1': 'A paragraph goes here.',
        'dacast-p1 dacast-p1--medium': 'A paragraph goes here.',
        'dacast-p1 dacast-p1--underline': 'A paragraph goes here.',
      },
    },
    {
      title: 'Paragraph 02',
      tag: 'p',
      elements: {
        'dacast-p2': 'A paragraph goes here.',
        'dacast-p2 dacast-p2--medium': 'A paragraph goes here.',
        'dacast-p2 dacast-p2--underline': 'A paragraph goes here.',
      },
    },
    {
      title: 'Paragraph 03',
      tag: 'p',
      elements: {
        'dacast-p3': 'A paragraph goes here.',
        'dacast-p3 dacast-p3--medium': 'A paragraph goes here.',
        'dacast-p3 dacast-p3--underline': 'A paragraph goes here.',
      },
    },
    {
      title: 'Caption',
      tag: 'p',
      elements: {
        'dacast-caption': 'A caption goes here.',
        'dacast-caption dacast-caption--bold': 'A caption goes here.',
        'dacast-caption dacast-caption--uppercase': 'A caption goes here.',
      },
    },
    {
      title: 'Footer',
      tag: 'p',
      elements: {
        'dacast-footer':
          'It is a long established fact that a reader will be distracted by the readable content.',
        'dacast-footer dacast-footer--bold':
          'It is a long established fact that a reader will be distracted by the readable content.',
        'dacast-footer dacast-footer--uppercase':
          'It is a long established fact that a reader will be distracted by the readable content.',
      },
    },
  ];
  const styledComponentsGuide = [
    {
      title: 'Display01, Display02',
      props: [
        {
          name: 'fontWeight',
          type: 'string',
          desc: '"medium", "bold", "black"',
        },
      ],
    },
    {
      title: 'Display01, Display02',
      props: [
        {
          name: 'fontWeight',
          type: 'string',
          desc: '"medium", "bold", "black"',
        },
      ],
    },
    {
      title: 'Heading01, Heading02, Heading03, Heading04, Heading05, Heading06',
      props: [
        {
          name: 'fontWeight',
          type: 'string',
          desc: '"medium", "bold", "black"',
        },
        {
          name: 'small',
          type: 'bool',
          desc: 'changes font size and line height',
        },
      ],
    },
    {
      title: 'Subheading',
      props: [
        {
          name: 'fontWeight',
          type: 'string',
          desc: '"light", "medium"',
        },
        {
          name: 'underline',
          type: 'bool',
          desc: 'adds underline',
        },
      ],
    },
    {
      title: 'Paragraph01, Paragraph02, Paragraph03',
      props: [
        {
          name: 'fontWeight',
          type: 'string',
          desc: '"light", "medium"',
        },
        {
          name: 'underline',
          type: 'bool',
          desc: 'adds underline',
        },
      ],
    },
    {
      title: 'Caption',
      props: [
        {
          name: 'fontWeight',
          type: 'string',
          desc: '"light", "medium"',
        },
        {
          name: 'uppercase',
          type: 'bool',
          desc: 'text transforms to uppercase and letter spacing to 0.08em',
        },
      ],
    },
    {
      title: 'Footer',
      props: [
        {
          name: 'fontWeight',
          type: 'string',
          desc: '"light", "medium"',
        },
        {
          name: 'uppercase',
          type: 'bool',
          desc: 'text transforms to uppercase and letter spacing to 0.02em',
        },
      ],
    },
  ];
  return (
    <>
      <StyleHeader>
        <TitleContainer>
          <h1>Typography</h1>
          <p>
            Font family: <span>Roboto</span>
          </p>
        </TitleContainer>
      </StyleHeader>
      <Wrapper>
        {data.map(({title, elements, tag}, i) => (
          <SectionContainer key={i}>
            <SectionTitleContainer>
              <SectionTitle>{title}</SectionTitle>
            </SectionTitleContainer>
            <FontStyleContainer>
              {Object.entries(elements).map((element, i) => (
                <TypographyContainer
                  title={title}
                  tag={tag}
                  element={element}
                  key={i}
                />
              ))}
            </FontStyleContainer>
          </SectionContainer>
        ))}
        <TableSectionTitle>
          <Heading03 fontWeight="bold">
            Styled Components Table of Props
          </Heading03>
        </TableSectionTitle>
        {styledComponentsGuide.map(({title, props}, i) => {
          return (
            <TableContainer key={`${i}-${title}`}>
              <Row>
                <Col>
                  <Heading04 fontWeight="medium" small>
                    {title}
                  </Heading04>
                </Col>
              </Row>
              <SectionTitleContainer>
                <Row>
                  <Col xs={1}>
                    <SectionTitle>prop</SectionTitle>
                  </Col>
                  <Col xs={1}>
                    <SectionTitle>type</SectionTitle>
                  </Col>
                  <Col xs={2}>
                    <SectionTitle>desc</SectionTitle>
                  </Col>
                </Row>
              </SectionTitleContainer>
              {/* eslint-disable-next-line react/prop-types */}
              {props.map(({name, type, desc}, i) => (
                <Row
                  key={`${i}-${name}`}
                  style={{
                    width: '100%',
                    marginTop: '10px',
                    marginBottom: '10px',
                    borderBottom: '1px solid #f1f1f1',
                  }}
                >
                  <Col xs={1}>{name}</Col>
                  <Col xs={1}>{type}</Col>
                  <Col xs={2}>{desc}</Col>
                </Row>
              ))}
            </TableContainer>
          );
        })}
      </Wrapper>
    </>
  );
};

export default TypographyGuide;

TypographyContainer.propTypes = {
  element: PropTypes.array,
  tag: PropTypes.string,
  title: PropTypes.string,
};

TypographyGuide.propTypes = {
  styledComponentsGuide: PropTypes.shape({
    title: PropTypes.string,
    props: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
        desc: PropTypes.string,
      })
    ),
  }),
};
