import styled from 'styled-components';
import theme from '../Layout/theme';

export const StyleHeader = styled.div`
  display: flex;
  height: 200px;
  width: 100%;
  background-color: ${theme.colors.gray9};
  justify-content: center;
  align-items: center;
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1440px;
  justify-content: center;
  padding-left: 32px;
  h1 {
    color: ${theme.colors.gray11};
    font-weight: 700;
    font-size: 72px;
    line-height: 84.38px;
    margin: 0 auto;
  }
  p {
    font-weight: 500;
    font-size: 24px;
    line-height: 48px;
    margin: 0;
    color: ${theme.colors.gray11};
    span {
      color: ${theme.colorGuide.neutral[800]};
    }
  }
`;
export const GridWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 12px 12px;
  grid-auto-flow: row;
  grid-template-areas:
    'blank StyledComponents StyledComponents HTML HTML'
    'blank . . . .'
    '. . . . .'
    '. . . . .'
    '. . . . .'
    '. . . . .'
    '. . . . .'
    '. . . . .'
    '. . . . .';
  margin: 64px 0;
  align-items: center;
`;
export const GridTitleColumn = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  justify-content: flex-start;
  margin: auto 0;
`;
export const GridTitleRow = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  justify-self: center;
`;
export const StyledTitle = styled.div`
  grid-area: StyledComponents;
  justify-self: center;
  text-transform: uppercase;
  font-weight: 500;
`;
export const HtmlTitle = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  grid-area: HTML;
  justify-self: center;
`;
export const Blank = styled.div`
  grid-area: blank;
`;
export const ColorGrid = styled.div`
  display: block;
  max-width: 1440px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 64px 0;
`;
export const ColorRow = styled.div`
  display: flex;
  height: 96px;
  margin: 22px auto;
  align-items: center;
  justify-content: start;
`;
export const RowTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
`;
export const CellTitle = styled.h1`
  font-size: 24px;
  line-height: 24px;
`;
export const CellDesc = styled.p`
  font-weight: 400;
  font-size: ${({theme}) => theme.fontSizes[12]};
  line-height: 16.8px;
  color: ${theme.colors.neutral600};
`;
export const ColorWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 100px;
  height: 75px;
  flex-direction: column;
  position: relative;
  margin: 0 12px;
  &:hover {
    cursor: pointer;
  }
`;
export const ColorTone = styled.div`
  background-color: ${({color}) => (color ? color : '#000')};
  border: ${({color}) => (color === '#FFFFFF' ? '1px solid #000000' : '0')};
  height: 50px;
  border-radius: 5px;
  transition: transform 0.2s ease;
  transform: ${({showToolTip}) => showToolTip && 'scale(1.08)'};
  &:active {
    transform: scale(0.98);
  }
`;
export const ColorData = styled.div`
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ColorScale = styled.h3`
  font-size: 12px;
  font-weight: 400;
  text-align: start;
  line-height: 12px;
  margin: 0;
`;
export const ColorHex = styled.h3`
  font-weight: 400;
  font-size: 12px;
  text-align: end;
  line-height: 12px;
  margin: 0;
`;
export const Tooltip = styled.div`
  position: absolute;
  top: 40px;
  right: -12px;
  background-color: ${({theme}) => theme.colorGuide.neutral[50]};
  display: ${({showToolTip}) => (showToolTip ? 'block' : 'none')};
  opacity: ${({showToolTip}) => (showToolTip ? '1' : '0')};
  border-radius: 5px;
  color: ${({theme}) => theme.colorGuide.neutral[800]};
  justify-content: center;
  align-items: center;
  z-index: 10;
  transform: ${({scale}) => `scaleX(${scale})`};
  border: 2px solid ${({theme}) => theme.colorGuide.neutral[400]};
  box-shadow: 0px 10px 9px -6px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 10px 9px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 9px -6px rgba(0, 0, 0, 0.75);
  transition: opacity 0.2s ease-in;
  &::before {
    content: '';
    width: 15px;
    height: 15px;
    background-color: ${({theme}) => theme.colorGuide.neutral[50]};
    position: absolute;
    top: -9px;
    right: 10%;
    transform: rotate(45deg);
    border-left: 2px solid ${({theme}) => theme.colorGuide.neutral[400]};
    border-top: 2px solid ${({theme}) => theme.colorGuide.neutral[400]};
  }
  p {
    padding: 8px;
    margin: 0 auto;
    font-size: ${({theme}) => theme.fontSizes[8]};
    text-align: center;
  }
`;
export const Copied = styled.div`
  opacity: ${({showCopied}) => (showCopied ? '1' : '0')};
  position: absolute;
  top: -20px;
  right: -10px;
  background-color: ${({theme}) => theme.colorGuide.neutral[50]};
  color: ${({theme}) => theme.colorGuide.neutral[800]};
  border: 2px solid ${({theme}) => theme.colorGuide.neutral[400]};
  padding: 4px;
  border-radius: 4px;
  transition: opacity 0.2s ease-in;
  &::before {
    content: 'On Clipboard!';
    font-size: ${({theme}) => theme.fontSizes[10]};
  }
`;
export const FontStyleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 100px;
  align-items: flex-start;
  justify-content: space-between;
  margin: 16px 0;
`;
export const FontWrapper = styled.div`
  position: relative;
`;
export const FontStyle = styled.div`
  width: 364px;
  margin: 8px 0px;
  cursor: pointer;
  transition: transform 0.2s ease;
  transform: ${({showToolTip}) => showToolTip && 'scale(1.08)'};
  &:active {
    transform: scale(0.98);
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1440px;
  margin: 0 auto;
  padding: 44px 39px;
`;
export const SectionTitleContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #94a3b8;
  margin-top: 24px;
`;
export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const SectionTitle = styled.h1`
  font-weight: 700;
  font-size: ${({big}) => (big ? '32px' : '16px')};
  line-height: 24px;
  color: ${({black}) => (black ? '#222f3e' : '#94a3b8')};
  font-style: normal;
`;
export const TableSectionTitle = styled.div`
  margin: 12px 0;
`;
export const TableContainer = styled.div`
  margin: 24px 0;
`;
export const SpacingGrid = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  padding: 44px 20px;
`;
export const SpacingItem = styled.div`
  display: flex;
  max-width: 100%;
  align-items: center;
  margin-bottom: 40px;
`;
export const SpacingWrapper = styled.div`
  display: flex;
  margin-left: 80px;
  width: ${({width}) => `${width}px`};
  background-color: ${({theme}) => theme.colorGuide.neutral[100]};
  transform: ${({scale}) => `scaleX(${scale})`};
  transform-origin: left;
  cursor: pointer;
  position: relative;
`;
export const SpacingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: ${({width}) => `${width}px`};
  margin: 0 auto;
  background-color: ${({theme}) => theme.colorGuide.neutral[50]};
`;
export const GridRow = styled.div`
  display: flex;
  flex: 1;
  height: 60px;
  justify-content: center;
  margin: 0 ${({margin}) => `${margin}`};
  div:not(:last-child) {
    margin-right: ${({spacing}) => `${spacing}px`};
  }
`;
export const GridCol = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid ${({theme}) => theme.colorGuide.error[500]};
  border-width: 0 1px 0 1px;
  background-color: ${({theme}) => theme.colorGuide.error[50]};
`;
